


































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ManageBuilding extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public building: any = {};

  public created() {
    this.$store.dispatch('loadBuilding', {
      buildingId: this.id,
      params: { recursive: true },
    })
      .then((response: any) => {
        this.building = response;
      });
  }

  public getBuildingIcon(building: any) {
    return this.$store.getters.getBuildingIcon(building);
  }

}
